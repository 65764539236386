import React from 'react';
import { Container } from '@components';
import './styles.scss';

const DataTable = () => (
  <Container>
    <div className="DataTable">
      <div className="item">
        <div className="icon">
          <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3334 5L14.5787 6.56266C13.7923 6.81643 12.9431 6.42253 12.6289 5.65831C12.3147 4.89408 12.6413 4.01675 13.3787 3.644L17.9854 1.34133C18.7795 0.947177 19.7005 0.896088 20.5334 1.2L27.3334 3.66666V13L19.3254 14.5213" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 13H8.00002L12.7867 18.3653C13.2854 18.9446 14.0919 19.1524 14.8084 18.8863C15.525 18.6202 16.0003 17.9364 16 17.172V16.3333L16.2387 16.4293C16.9341 16.7071 17.7219 16.622 18.3419 16.202C18.9619 15.7821 19.3333 15.0821 19.3334 14.3333H20C20.7576 14.3333 21.4501 13.9053 21.7889 13.2278C22.1277 12.5502 22.0545 11.7394 21.6 11.1333L17.3334 5.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.0694 3.29199L13.5814 2.89199C12.7856 2.30242 11.7414 2.16672 10.8214 2.53333L4.66669 4.99999" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.666687 2.33333H3.33335C4.06973 2.33333 4.66669 2.93028 4.66669 3.66666V13C4.66669 13.7364 4.06973 14.3333 3.33335 14.3333H0.666687" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.3334 14.3333H28.6667C27.9303 14.3333 27.3334 13.7364 27.3334 13V3.66666C27.3334 2.93028 27.9303 2.33333 28.6667 2.33333H31.3334" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 16.3333L14.6667 15" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.3334 12.3333L19.3334 14.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">140+</div>
          <div className="label">Deals managed</div>
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.672 6.00001C16.1948 6.00001 20.672 4.8061 20.672 3.33334C20.672 1.86058 16.1948 0.666672 10.672 0.666672C5.14915 0.666672 0.671997 1.86058 0.671997 3.33334C0.671997 4.8061 5.14915 6.00001 10.672 6.00001Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.6667 3.33334V7.33334C20.6667 8.80668 16.1907 10 10.6667 10C5.14266 10 0.666656 8.80668 0.666656 7.33334V3.33334" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.6667 7.33334V11.3333C20.6667 12.8067 16.1907 14 10.6667 14C5.14266 14 0.666656 12.8067 0.666656 11.3333V7.33334" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.3387 23.3333C26.8615 23.3333 31.3387 22.1394 31.3387 20.6667C31.3387 19.1939 26.8615 18 21.3387 18C15.8158 18 11.3387 19.1939 11.3387 20.6667C11.3387 22.1394 15.8158 23.3333 21.3387 23.3333Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.3333 20.6667V24.6667C31.3333 26.14 26.8573 27.3333 21.3333 27.3333C15.8093 27.3333 11.3333 26.14 11.3333 24.6667V20.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.3333 24.6667V28.6667C31.3333 30.14 26.8573 31.3333 21.3333 31.3333C15.8093 31.3333 11.3333 30.14 11.3333 28.6667V24.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.6667 11.3333V15.3333C20.6667 16.8067 16.1907 18 10.6667 18C5.14266 18 0.666656 16.8067 0.666656 15.3333V11.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.666656 15.3333V19.3333C0.666656 20.8067 5.14399 22 10.6667 22H11.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.666656 19.3333V23.3333C0.666656 24.8067 5.14399 26 10.6667 26H11.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.672 15.3333V18" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">41%</div>
          <div className="label">Internal rate of return</div>
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30.6667 7.33334C31.0349 7.33334 31.3333 7.03486 31.3333 6.66667V3.33334C31.3333 1.86058 30.1394 0.666672 28.6667 0.666672H3.33333C1.86057 0.666672 0.666666 1.86058 0.666666 3.33334V6.66667C0.666666 7.03486 0.965143 7.33334 1.33333 7.33334C3.91066 7.33334 6 9.42268 6 12C6 14.5773 3.91066 16.6667 1.33333 16.6667C0.965143 16.6667 0.666666 16.9651 0.666666 17.3333V20.6667C0.666666 22.1394 1.86057 23.3333 3.33333 23.3333H28.6667C30.1394 23.3333 31.3333 22.1394 31.3333 20.6667V17.3333C31.3333 16.9651 31.0349 16.6667 30.6667 16.6667C28.0893 16.6667 26 14.5773 26 12C26 9.42268 28.0893 7.33334 30.6667 7.33334Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.384 7.588C15.4879 7.34048 15.7302 7.17946 15.9987 7.17946C16.2671 7.17946 16.5094 7.34048 16.6133 7.588L17.72 10.2333H19.8667C20.1492 10.2334 20.401 10.4116 20.4951 10.678C20.5892 10.9444 20.5051 11.2412 20.2853 11.4187L18.3 13.0187L19.2587 15.576C19.3616 15.8504 19.2732 16.1599 19.0409 16.3385C18.8086 16.5172 18.4868 16.5232 18.248 16.3533L16 14.7533L13.752 16.3533C13.5134 16.5238 13.1914 16.5184 12.9587 16.34C12.726 16.1615 12.6373 15.852 12.74 15.5773L13.6987 13.02L11.7133 11.42C11.4935 11.2425 11.4095 10.9457 11.5036 10.6793C11.5977 10.4129 11.8495 10.2347 12.132 10.2347H14.2667L15.384 7.588Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 3.66667C6.18409 3.66667 6.33333 3.81591 6.33333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.66667 4.00001C5.66667 3.81591 5.8159 3.66667 6 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 4.33333C5.8159 4.33333 5.66667 4.18409 5.66667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.33333 4C6.33333 4.18409 6.18409 4.33333 6 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 3.66667C10.1841 3.66667 10.3333 3.81591 10.3333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66667 4.00001C9.66667 3.81591 9.8159 3.66667 10 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 4.33333C9.8159 4.33333 9.66667 4.18409 9.66667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3333 4C10.3333 4.18409 10.1841 4.33333 10 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 3.66667C14.1841 3.66667 14.3333 3.81591 14.3333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.6667 4.00001C13.6667 3.81591 13.8159 3.66667 14 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 4.33333C13.8159 4.33333 13.6667 4.18409 13.6667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3333 4C14.3333 4.18409 14.1841 4.33333 14 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 3.66667C18.1841 3.66667 18.3333 3.81591 18.3333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6667 4.00001C17.6667 3.81591 17.8159 3.66667 18 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 4.33333C17.8159 4.33333 17.6667 4.18409 17.6667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3333 4C18.3333 4.18409 18.1841 4.33333 18 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 3.66667C22.1841 3.66667 22.3333 3.81591 22.3333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.6667 4.00001C21.6667 3.81591 21.8159 3.66667 22 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 4.33333C21.8159 4.33333 21.6667 4.18409 21.6667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.3333 4C22.3333 4.18409 22.1841 4.33333 22 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 3.66667C26.1841 3.66667 26.3333 3.81591 26.3333 4.00001" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.6667 4.00001C25.6667 3.81591 25.8159 3.66667 26 3.66667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 4.33333C25.8159 4.33333 25.6667 4.18409 25.6667 4" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.3333 4C26.3333 4.18409 26.1841 4.33333 26 4.33333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 19.6667C6.18409 19.6667 6.33333 19.8159 6.33333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.66667 20C5.66667 19.8159 5.8159 19.6667 6 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 20.3333C5.8159 20.3333 5.66667 20.1841 5.66667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.33333 20C6.33333 20.1841 6.18409 20.3333 6 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 19.6667C10.1841 19.6667 10.3333 19.8159 10.3333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66667 20C9.66667 19.8159 9.8159 19.6667 10 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 20.3333C9.8159 20.3333 9.66667 20.1841 9.66667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3333 20C10.3333 20.1841 10.1841 20.3333 10 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 19.6667C14.1841 19.6667 14.3333 19.8159 14.3333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.6667 20C13.6667 19.8159 13.8159 19.6667 14 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 20.3333C13.8159 20.3333 13.6667 20.1841 13.6667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3333 20C14.3333 20.1841 14.1841 20.3333 14 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 19.6667C18.1841 19.6667 18.3333 19.8159 18.3333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6667 20C17.6667 19.8159 17.8159 19.6667 18 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 20.3333C17.8159 20.3333 17.6667 20.1841 17.6667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3333 20C18.3333 20.1841 18.1841 20.3333 18 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 19.6667C22.1841 19.6667 22.3333 19.8159 22.3333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.6667 20C21.6667 19.8159 21.8159 19.6667 22 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 20.3333C21.8159 20.3333 21.6667 20.1841 21.6667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.3333 20C22.3333 20.1841 22.1841 20.3333 22 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 19.6667C26.1841 19.6667 26.3333 19.8159 26.3333 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.6667 20C25.6667 19.8159 25.8159 19.6667 26 19.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 20.3333C25.8159 20.3333 25.6667 20.1841 25.6667 20" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.3333 20C26.3333 20.1841 26.1841 20.3333 26 20.3333" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">20%</div>
          <div className="label">Platform admittance</div>
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.67603 22.012C-3.67064 16.4987 0.200025 0.666656 12 0.666656C16.2311 0.675873 20.1056 3.03848 22.0512 6.79572C23.9968 10.5529 23.6903 15.0806 21.256 18.5413" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.66669 31.3333L13.8254 21.4133C14.311 20.982 15.0369 20.9634 15.544 21.3693L20.4334 25.28C20.9503 25.6934 21.6922 25.665 22.176 25.2133L31.3334 16.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.996 16.6667H31.3294V22" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">€ 280+ million</div>
          <div className="label">Funding volume</div>
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.671997 19.3067C0.671997 19.0305 0.895855 18.8067 1.172 18.8067H5.50533C5.78147 18.8067 6.00533 19.0305 6.00533 19.3067V28.9733C6.00533 29.2495 5.78147 29.4733 5.50533 29.4733H1.172C0.895855 29.4733 0.671997 29.2495 0.671997 28.9733V19.3067Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 27.4173C20 32.084 15.3333 32.084 31.3333 24.084C30.4125 22.8434 28.8144 22.3107 27.3333 22.7506L21.4667 24.6986" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 20.14H10C12.2694 20.2204 14.4196 21.1761 16 22.8067H20C20.5333 22.7109 21.0723 22.942 21.3705 23.3944C21.6687 23.8467 21.6687 24.4332 21.3705 24.8856C21.0723 25.3379 20.5333 25.5691 20 25.4733H12.6667" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.3387 9.41733C25.5478 9.41733 27.3387 7.62647 27.3387 5.41733C27.3387 3.20819 25.5478 1.41733 23.3387 1.41733C21.1295 1.41733 19.3387 3.20819 19.3387 5.41733C19.3387 7.62647 21.1295 9.41733 23.3387 9.41733Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.672 18.7507C18.8811 18.7507 20.672 16.9598 20.672 14.7507C20.672 12.5415 18.8811 10.7507 16.672 10.7507C14.4629 10.7507 12.672 12.5415 12.672 14.7507C12.672 16.9598 14.4629 18.7507 16.672 18.7507Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.672 13.4173V16.084" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.3387 4.08398V6.75065" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">over €8 million</div>
          <div className="label">Average deal size</div>
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <svg width="18" height="31" viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.34 15.6653L1.95867 2.57066C1.88801 2.1823 1.99306 1.7826 2.24553 1.47918C2.498 1.17575 2.87194 0.999782 3.26667 0.998657H14.7333C15.1288 0.998598 15.5038 1.17406 15.7572 1.47764C16.0106 1.78123 16.1161 2.18162 16.0453 2.57066L13.664 15.6653" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.00667 0.998657V10.332" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0067 0.998657V10.332" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.94133 14.908L11.9933 19.668H15.9867C16.3995 19.653 16.7774 19.8984 16.9315 20.2817C17.0857 20.6649 16.9829 21.1037 16.6747 21.3787L13.208 24.4667L15.128 28.884C15.3008 29.2983 15.1902 29.7766 14.8531 30.073C14.5159 30.3693 14.0274 30.4177 13.6387 30.1933L9 27.5787L4.36 30.1933C3.97109 30.4209 3.48018 30.374 3.14137 30.0769C2.80256 29.7799 2.6919 29.2993 2.86667 28.884L4.78667 24.4667L1.32 21.38C1.0111 21.1055 0.907537 20.6668 1.06111 20.2832C1.21467 19.8996 1.59237 19.6535 2.00533 19.668H6.00533L8.06 14.908C8.24555 14.5609 8.60712 14.3442 9.00067 14.3442C9.39421 14.3442 9.75578 14.5609 9.94133 14.908Z" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="data">
          <div className="value">0%</div>
          <div className="label">Funded companies failure rate</div>
        </div>
      </div>
    </div>
  </Container>
);

export default DataTable;
